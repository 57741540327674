/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

import { CircularProgressbar } from "react-circular-progressbar";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// core component
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Battery from "components/Battery/Battery.jsx";
import ReactEcharts from "echarts-for-react";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import NavPills from "components/NavPills/NavPills.js";
import Button from "components/CustomButtons/Button.jsx";

import LCD from "components/LCD/LCD.jsx";
import DeviceDetailsTable from "components/DeviceDetailsTable/DeviceDetailsTable.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import MapLeft from "components/MapLeft/MapLeft.jsx";

import {
  getUserDevices,
  downloadDeviceData,
  getDeviceLogs
} from "../../services/dashboardDataProvider";

import "react-circular-progressbar/dist/styles.css";
import { DEVICE_TYPES } from "../../utils/deviceTypes.jsx";
import { prettyJson } from "../../utils/converters.jsx";
import { MEDIA_ROOT } from "config.js";

import bulbIcon from "assets/img/bulb.webp";
import cflIcon from "assets/img/CFL.png";
import acIcon from "assets/img/air-conditioner.webp";
import fanIcon from "assets/img/fan.jpg";
import fridgeIcon from "assets/img/fridge.webp";
import pumpIcon from "assets/img/pump.png";
import motorIcon from "assets/img/motor.jpeg";

import {
  DATE_DISPLAY_FORMAT,
  DATE_DISPLAY_FORMAT_DETAILED,
  dayjs
} from "utils/time.jsx";

import {
  PERMISSIONS_ADMIN,
  CAN_SEE_DEVICE_LOGS,
  CAN_SEE_RAW_DATA_DETAILS,
  RAW_DATA_KEYS_TO_HIDE
} from "utils/userPermissions.jsx";

const loadIcons = {
  Bulb: bulbIcon,
  CFL: cflIcon,
  AC: acIcon,
  Fan: fanIcon,
  Refrigerator: fridgeIcon,
  "Water pump": pumpIcon,
  "Agriculture Machine": motorIcon
};

const styles = {
  img: {
    width: "auto",
    height: "auto",
    verticalAlign: "middle",
    border: "0"
  },
  loadOnImg: {
    width: "60px",
    height: "auto",
    backgroundColor: "lightgreen"
  },
  loadOffImg: {
    width: "60px",
    height: "auto"
  },
  cardTitleBlack: {
    color: "#273746",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "600",
    fontFamily: "'Josefin Sans', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  statusSummaryContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly"
  },
  statusSummaryIndicatorsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    flexWrap: "wrap"
  },
  statusSummaryItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    margin: "10px"
  },
  StatusSummaryItemTitle: {
    minWidth: "130px !important"
  },
  StatusSummaryItemWidget: {
    width: "auto"
  },
  statusItemChip: {
    marginTop: "15px"
  },
  statusSummaryControls: {
    margin: "10px"
  },
  cardIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  deviceInfoHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    background: "hsla(0,0%,98%,.85)",
    boxShadow: "0px 0px 1px 0 hsl(0deg 0% 6% / 40%)",
    borderRadius: "20px"
  },
  deviceInfo: {
    padding: "10px"
  },
  deviceWeatherInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "10px"
  },
  cityName: {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline"
  },
  cityTemperature: {
    color: "#1e2432",
    fontSize: "3rem",
    fontWeight: "200",
    textAlign: "center"
  },
  cityWeatherImage: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
  },
  cityWeatherIcon: {
    height: "100px",
    width: "100px"
  },
  rawTelemetryDataContainer: {
    display: "flex",
    flexDirection: "column",
    overflowX: "scroll"
  },
  jsonContainer: {
    display: "flex",
    flexDirection: "column"
  },
  jsonKeyValuePair: {
    display: "flex",
    flexDirection: "row"
  },
  jsonKey: {
    fontSize: "larger",
    color: "#0057ff",
    display: "flex",
    flexDirection: "row"
  },
  jsonKeyValue: {
    display: "flex",
    flexDirection: "row",
    color: "#ff00a0",
    paddingLeft: "5px"
  },
  updateInfo: {
    color: "blue"
  },
  dataUpdateInfo: {
    color: "blueviolet"
  },
  updateInfoLabel: {
    color: "blue"
  },
  dataUpdateInfoLabel: {
    color: "blueviolet"
  },
  chartSelectionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  chartTypeLabel: {
    paddingRight: "10px"
  },
  chartTypeSelector: {
    width: "115px !important"
  },
  alarmActions: {
    display: "flex",
    justifyContent: "flex-end"
  },
  alarmModalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    opacity: "100%",
    zIndex: 2000,
    backgroundColor: "white",
    borderRadius: "10px",
    border: "1px solid rgba(0, 0, 0, 0.3)",
    "-webkit-box-shadow": "0 3px 7px rgba(0, 0, 0, 0.3)",
    "-moz-box-shadow": "0 3px 7px rgba(0, 0, 0, 0.3)",
    boxShadow: "0 3px 7px rgba(0, 0, 0, 0.3)",
    "-webkit-background-clip": "padding-box",
    "-moz-background-clip": "padding-box",
    backgroundClip: "padding-box"
  },
  alarmSelectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px"
  },
  deviceLogActions: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column"
  },
  deviceLogs: {
    whiteSpace: "pre-line",
    background: "black",
    color: "aliceblue",
    overflow: "scroll",
    maxHeight: "400px"
  }
};

const AVAILABLE_CHART_TYPES = [
  { text: "Power", value: "power" },
  { text: "Energy", value: "energy" },
  { text: "Battery Status", value: "battery status" },
  { text: "System Temperature", value: "system temperature" }
];

const AVAILABLE_ALARM_TYPES = [
  { text: "Daily Report", value: "DAILY_REPORT" },
  { text: "Weekly Report", value: "WEEKLY_REPORT" },
  { text: "Monthly Report", value: "MONTHLY_REPORT" }
];

const MeterTypes = {
  GRID_METER: "grid_meter",
  LOAD_METER: "load_meter",
  SOLAR_METER: "solar_meter",
  WEATHER_METER: "weather_meter",
  STATUS_METER: "status_meter"
};

let logsInterval = null;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceId: null,
      devices: [],
      showTodaysStatus: true,
      editDeviceDetails: false,
      showRawData: false,
      visibleChartType: AVAILABLE_CHART_TYPES[0].text,
      alarmModalOpen: false,
      deviceLogs: null,
      logsInputData: {
        date: null,
        logSearchPattern: ""
      }
    };
  }

  filterLogs = logSearchPattern => {
    let logs = this.state.deviceLogs;
    logs =
      logs &&
      logs.filter(logText => logText.match(logSearchPattern)).join("\n");
    return logs;
  };

  fetchDeviceLogs = deviceId => {
    const { user } = this.props;
    const userPermissions = user.user_permissions || [];
    const userCanSeeLogs =
      userPermissions.includes(PERMISSIONS_ADMIN) ||
      userPermissions.includes(CAN_SEE_DEVICE_LOGS);
    const date = this.state.logsInputData.date || dayjs().format("YYYY-MM-DD");
    if (userCanSeeLogs) {
      getDeviceLogs(
        this.props.axios,
        this.state.deviceId || deviceId,
        date,
        "json"
      ).then(data => {
        this.setState({ deviceLogs: data["logs"] });
      });
    }
  };

  timeToFetch = () => {
    if (this.state.shouldFetchLogs) {
      this.fetchDeviceLogs();
    }
    // getUserDevices(this.props.axios, this.state.deviceId).then(data => {
    //   this.setState({ deviceId: this.state.deviceId, deviceData: data }, () => {
    //     const deviceType = data.type;
    //     if (!Object.keys(DEVICE_TYPES).includes(deviceType)) {
    //       this.setState({ showRawData: true });
    //     }
    //   });
    // });
  };

  handleTabChange = activeTab => {
    this.setState({ shouldFetchLogs: activeTab === 4 });
    if (activeTab !== 4) {
      clearInterval(logsInterval);
    }
  };

  onSelectionChange = (dictName, propName) => value => {
    if (dictName == null || dictName === "") {
      this.setState({ [propName]: value });
    } else {
      let updatedData = this.state[dictName] || {};
      updatedData[propName] = value;
      this.setState({ [dictName]: updatedData });
    }
  };

  onChange = (dictName, propName) => e => {
    const value = e.target.value;
    if (dictName == null || dictName === "") {
      this.setState({ [propName]: value });
    } else {
      let updatedData = this.state[dictName] || {};
      updatedData[propName] = value;
      this.setState({ [dictName]: updatedData });
    }

    if (dictName === "logsInputData") {
      console.log("logsInputData", this.state.logsInputData);
      if (propName === "date") {
        this.fetchDeviceLogs();
      }
    }
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  addNewChart = deviceData => {
    const existingCharts = this.state.visibleCharts || [];
    existingCharts.push({
      date: null,
      visibleChartType: "power"
    });
    this.setState({ visibleCharts: existingCharts });
  };

  onChartDateChange = (idx, propName) => e => {
    const value = e.target.value;
    const visibleCharts = this.state.visibleCharts || [];
    if (visibleCharts[idx] == null) {
      visibleCharts[idx] = {};
    }
    visibleCharts[idx][propName] = value;
    this.setState({ visibleCharts: visibleCharts });

    let payload = visibleCharts[idx] || {};
    payload["exportType"] = "json";
    payload["dataType"] = "status";
    payload["statusData"] = null;
    payload["startDate"] = payload["date"];
    let deviceId = this.props.match.params && this.props.match.params.deviceId;
    if (deviceId) {
      downloadDeviceData(this.props.axios, deviceId, payload).then(data => {
        visibleCharts[idx]["statusData"] = data;
        this.setState({ visibleCharts: visibleCharts });
      });
    }
  };

  onChartTypeChange = (idx, propName) => value => {
    if (idx == null) {
      this.setState({ [propName]: value });
    } else {
      const visibleCharts = this.state.visibleCharts || [];
      if (visibleCharts[idx] == null) {
        visibleCharts[idx] = {};
      }
      visibleCharts[idx][propName] = value;
      this.setState({ visibleCharts: visibleCharts });
    }
  };

  downloadDeviceData() {
    let payload = this.state.downloadData || {};
    payload["exportType"] = "csv";
    let deviceId = this.props.match.params && this.props.match.params.deviceId;
    if (deviceId) {
      downloadDeviceData(this.props.axios, deviceId, payload).then(data => {
        const blob = new Blob([data], { type: "text/csv" });
        // Creating an object for downloading url
        const url = window.URL.createObjectURL(blob);
        // Creating an anchor(a) tag of HTML
        const a = document.createElement("a");

        // Passing the blob downloading url
        a.setAttribute("href", url);

        // Setting the anchor tag attribute for downloading
        // and passing the download file name
        a.setAttribute("download", "device-raw-data.csv");

        // Performing a download with click
        a.click();
        this.setState({ dataDownloadModalOpen: false });
      });
    }
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    let deviceId = this.props.match.params && this.props.match.params.deviceId;
    if (deviceId) {
      getUserDevices(this.props.axios, deviceId).then(data => {
        this.setState({ deviceId: deviceId, deviceData: data }, () => {
          const deviceType = data.type;
          if (!Object.keys(DEVICE_TYPES).includes(deviceType)) {
            this.setState({ showRawData: true });
          }
        });
      });
      this.fetchDeviceLogs(deviceId);
    }
  }

  renderStatusSummary(deviceData) {
    const { classes } = this.props;
    if (deviceData.properties == null) {
      return null;
    }
    let currency = deviceData.properties.currency || "$";
    let performanceTrackerTitle =
      deviceData.properties.performance_tracker_title || "Savings this month:";
    let monthlyBillLimit =
      parseFloat(deviceData.properties.monthly_bill_limit) || 1;
    let monthlyBillAmount =
      (deviceData.properties.cost_savings_this_month &&
        parseFloat(deviceData.properties.cost_savings_this_month).toFixed(1)) ||
      (deviceData.properties.monthly_bill_amount &&
        parseFloat(deviceData.properties.monthly_bill_amount).toFixed(1)) ||
      0;
    let percentage = ((monthlyBillAmount * 100) / monthlyBillLimit).toFixed(1);

    let energyConsumptionLimit =
      parseFloat(deviceData.properties.energy_consumption_limit_this_month) ||
      1;
    let energyConsumptionAmount =
      (deviceData.properties.energy_consumed_this_month &&
        parseFloat(deviceData.properties.energy_consumed_this_month).toFixed(
          1
        )) ||
      0;
    let percentageEnergyConsumption = (
      (energyConsumptionAmount * 100) /
      energyConsumptionLimit
    ).toFixed(1);

    let energyGenerationLimit =
      parseFloat(deviceData.properties.energy_generation_limit_this_month) || 1;
    let energyGenerationAmount =
      (deviceData.properties.energy_generated_this_month &&
        parseFloat(deviceData.properties.energy_generated_this_month).toFixed(
          1
        )) ||
      0;
    let percentageEnergyGeneration = (
      (energyGenerationAmount * 100) /
      energyGenerationLimit
    ).toFixed(1);

    let energyExportedAmount =
      (deviceData.properties.energy_exported_this_month &&
        parseFloat(deviceData.properties.energy_exported_this_month).toFixed(
          1
        )) ||
      0;

    let energyImportedAmount =
      (deviceData.properties.energy_imported_this_month &&
        parseFloat(deviceData.properties.energy_imported_this_month).toFixed(
          1
        )) ||
      0;

    if (this.state.showTodaysStatus) {
      energyConsumptionLimit =
        parseFloat(deviceData.properties.energy_consumption_limit_this_day) ||
        1;
      energyConsumptionAmount =
        (deviceData.properties.energy_consumed_this_day &&
          parseFloat(deviceData.properties.energy_consumed_this_day).toFixed(
            1
          )) ||
        0;
      percentageEnergyConsumption = (
        (energyConsumptionAmount * 100) /
        energyConsumptionLimit
      ).toFixed(1);

      energyGenerationLimit =
        parseFloat(deviceData.properties.energy_generation_limit_this_day) || 1;
      energyGenerationAmount =
        (deviceData.properties.energy_generated_this_day &&
          parseFloat(deviceData.properties.energy_generated_this_day).toFixed(
            1
          )) ||
        0;
      percentageEnergyGeneration = (
        (energyGenerationAmount * 100) /
        energyGenerationLimit
      ).toFixed(1);

      energyExportedAmount =
        (deviceData.properties.energy_exported_this_day &&
          parseFloat(deviceData.properties.energy_exported_this_day).toFixed(
            1
          )) ||
        0;

      energyImportedAmount =
        (deviceData.properties.energy_imported_this_day &&
          parseFloat(deviceData.properties.energy_imported_this_day).toFixed(
            1
          )) ||
        0;
    }

    const activeAlarms = (deviceData.alarms || []).length;

    return (
      <div className={classes.statusSummaryContainer}>
        <div className={classes.statusSummaryIndicatorsContainer}>
          <div className={classes.statusSummaryItem}>
            <h4 className={classes.StatusSummaryItemTitle}>
              {performanceTrackerTitle}
            </h4>
            <CircularProgressbar
              className={classes.StatusSummaryItemWidget}
              value={percentage}
              text={`${monthlyBillAmount} ${currency}`}
            />
            <Chip
              label={`Rate ${deviceData.properties.pay_per_unit} ${currency}`}
              color="primary"
              variant="outlined"
              className={classes.statusItemChip}
            />
          </div>
          {activeAlarms > 0 && (
            <div className={classes.statusSummaryItem}>
              <h4 className={classes.StatusSummaryItemTitle}>Active alarms</h4>
              <CircularProgressbar
                className={classes.StatusSummaryItemWidget}
                value={activeAlarms}
                text={`${activeAlarms}`}
              />
            </div>
          )}
          <div className={classes.statusSummaryItem}>
            <h4 className={classes.StatusSummaryItemTitle}>
              Energy generated(KWh)
            </h4>
            <CircularProgressbar
              className={classes.StatusSummaryItemWidget}
              value={percentageEnergyGeneration}
              text={`${energyGenerationAmount}`}
            />
            <Chip
              label={`Exported ${energyExportedAmount} KWh`}
              color="primary"
              variant="outlined"
              className={classes.statusItemChip}
            />
          </div>
          <div className={classes.statusSummaryItem}>
            <h4 className={classes.StatusSummaryItemTitle}>
              Energy consumed(KWh)
            </h4>
            <CircularProgressbar
              className={classes.StatusSummaryItemWidget}
              value={percentageEnergyConsumption}
              text={`${energyConsumptionAmount}`}
            />
            <Chip
              label={`Imported ${energyImportedAmount} KWh`}
              color="primary"
              variant="outlined"
              className={classes.statusItemChip}
            />
          </div>
        </div>
        <div className={classes.statusSummaryControls}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.showTodaysStatus}
                onChange={() => {
                  this.setState({
                    showTodaysStatus: !this.state.showTodaysStatus
                  });
                }}
              />
            }
            label="Show Today"
          />
        </div>
      </div>
    );
  }

  renderDeviceMeters(deviceData) {
    let meterTypes = Object.keys(deviceData.latest_data);
    meterTypes = meterTypes.sort();
    return (
      <GridContainer>
        {meterTypes.map((meterType, idx) => {
          if (
            meterType.indexOf("meter") > -1 ||
            meterType.indexOf("last_update_time") > -1
          ) {
            let data = deviceData.latest_data[meterType];
            let rows = [];
            if (meterType.indexOf("last_update_time") > -1) {
              rows.push([
                "Update Time",
                dayjs(data).format(DATE_DISPLAY_FORMAT)
              ]);
            } else if (data !== null) {
              Object.keys(data).forEach(dataKey => {
                let dataTitle = dataKey;
                let dataVal = data[dataKey];
                let addKey = false;
                if (
                  dataVal !== null &&
                  dataKey !== "id" &&
                  dataKey !== "meter"
                ) {
                  if (
                    dataKey === "data_arrival_time" ||
                    dataKey === "last_update_time"
                  ) {
                    dataVal = dayjs(dataVal).format(DATE_DISPLAY_FORMAT);
                    dataTitle = "Update Time";
                    addKey = true;
                  } else if (
                    dataKey === "energy" &&
                    [
                      MeterTypes.GRID_METER,
                      MeterTypes.LOAD_METER,
                      MeterTypes.SOLAR_METER
                    ].indexOf(meterType) >= 0
                  ) {
                    dataVal = dataVal.toFixed(2);
                    dataVal = `${dataVal} KWH`;
                    dataTitle = "Energy";
                    addKey = true;
                  } else if (
                    dataKey === "runtime" &&
                    meterType === MeterTypes.STATUS_METER
                  ) {
                    dataVal = (dataVal / 3600).toFixed(2);
                    dataVal = `${dataVal} Hours`;
                    dataTitle = "Runtime";
                    addKey = true;
                  } else if (
                    dataKey === "voltage" &&
                    [
                      MeterTypes.GRID_METER,
                      MeterTypes.LOAD_METER,
                      MeterTypes.SOLAR_METER
                    ].indexOf(meterType) >= 0
                  ) {
                    dataVal = dataVal.toFixed(2);
                    dataVal = `${dataVal} Volts`;
                    dataTitle = "Voltage";
                    addKey = true;
                  } else if (
                    dataKey === "current" &&
                    [
                      MeterTypes.GRID_METER,
                      MeterTypes.LOAD_METER,
                      MeterTypes.SOLAR_METER
                    ].indexOf(meterType) >= 0
                  ) {
                    dataVal = dataVal.toFixed(2);
                    dataVal = `${dataVal} Amp`;
                    dataTitle = "Current";
                    addKey = true;
                  } else if (
                    dataKey === "power" &&
                    [
                      MeterTypes.GRID_METER,
                      MeterTypes.LOAD_METER,
                      MeterTypes.SOLAR_METER
                    ].indexOf(meterType) >= 0
                  ) {
                    dataVal = dataVal.toFixed(2);
                    dataVal = `${dataVal} Watts`;
                    dataTitle = "Load";
                    addKey = true;
                  } else if (
                    dataKey === "frequency" &&
                    [MeterTypes.GRID_METER, MeterTypes.LOAD_METER].indexOf(
                      meterType
                    ) >= 0
                  ) {
                    dataVal = dataVal.toFixed(2);
                    dataVal = `${dataVal} Hz`;
                    dataTitle = "Frequency";
                    addKey = true;
                  } else if (
                    dataKey === "humidity" &&
                    [MeterTypes.WEATHER_METER].indexOf(meterType) >= 0
                  ) {
                    dataVal = dataVal.toFixed(1);
                    dataVal = `${dataVal} %`;
                    dataTitle = "Humidity";
                    addKey = true;
                  } else if (
                    dataKey === "temperature" &&
                    [MeterTypes.WEATHER_METER].indexOf(meterType) >= 0
                  ) {
                    dataVal = dataVal.toFixed(1);
                    dataVal = `${dataVal} C`;
                    dataTitle = "Temperature";
                    addKey = true;
                  }
                  if (addKey) {
                    rows.push([dataTitle, dataVal]);
                  }
                }
              });
            }
            return (
              <GridItem
                key={`meter-data-${meterType}-${idx}`}
                xs={12}
                sm={12}
                md={12}
              >
                <h4>
                  <strong>{meterType.toUpperCase().replace("_", " ")}</strong>
                </h4>
                <Table
                  tableHeaderColor="primary"
                  tableData={rows}
                  coloredColls={[3]}
                  colorsColls={["primary"]}
                />
              </GridItem>
            );
          }
          return null;
        })}
      </GridContainer>
    );
  }

  renderDeviceTips(deviceData) {
    const deviceTips = deviceData.tips || [];
    return (
      deviceTips.length > 0 && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h3>
              <strong>Tips for today</strong>
            </h3>
            <Table
              tableHeaderColor="primary"
              tableData={deviceTips.map(tip => [tip])}
              coloredColls={[3]}
              colorsColls={["primary"]}
            />
          </GridItem>
        </GridContainer>
      )
    );
  }

  transformValue = (dataType, status, valueField) => {
    let value = null;
    if (Array.isArray(valueField)) {
      return valueField.reduce((filtered, field) => {
        let val = status[field];
        if (val != null && val !== "NA") {
          filtered.push({ [field]: val });
        }
        return filtered;
      }, []);
    } else {
      value = status[valueField];
    }
    if (value == null) return value;
    if (typeof value === "number") return value.toFixed(2);
    if (dataType === "battery_charging_status") {
      if (value.includes("Charging")) {
        value = value.replace("Charging", "").replace(" W", "");
        return parseFloat(value).toFixed(2);
      }
      if (value.includes("Discharging")) {
        value = value.replace("Discharging", "").replace(" W", "");
        return (-1 * parseFloat(value)).toFixed(2);
      }
    }

    if (dataType === "solar_status" || dataType === "load_status") {
      return value.replace(" W", "");
    }

    if (
      dataType === "energy_generated_this_day" ||
      dataType === "energy_consumed_this_day"
    ) {
      return value.toFixed(2);
    }

    if (dataType === "net_meter_status") {
      return value;
    }

    if (dataType === "system_temperature") {
      value = value.split(" C, ")[0];
      return parseFloat(value).toFixed(2);
    }

    if (dataType === "system_humidity") {
      value = value.split(" C, ")[1].replace(" H", "");
      return parseFloat(value).toFixed(2);
    }
    return value;
  };

  renderConsumptionChart(
    deviceData,
    showDateSelection = false,
    chartIdx = null
  ) {
    const tz =
      (deviceData.other_data && deviceData.other_data.timezone) ||
      dayjs.tz.guess();
    const { classes } = this.props;
    const availableStatusTypes = deviceData.available_status_types;
    let statusNames = [];
    if (availableStatusTypes) {
      statusNames = availableStatusTypes.map(st => st.name);
    }
    let dataType1 = "solar_status";
    let dataType2 = "load_status";
    let valueField1 = "solar_status";
    let valueField2 = "load_status";
    let valueField3 = [
      "system_state",
      "net_meter_status",
      "battery_charging_status",
      "load_status",
      "solar_status"
    ];

    let labelField1 = "Power generated(W)";
    let labelField2 = "Power consumed(W)";
    let labelField3 = "System status";

    const visibleCharts = this.state.visibleCharts || [];
    const chartInputData = visibleCharts[chartIdx];
    let visibleChartType = this.state.visibleChartType;
    let selectedDay = "Today's";
    let allStatusData = deviceData.status_data_today;
    if (chartInputData != null) {
      visibleChartType = chartInputData.visibleChartType;
      selectedDay = `${chartInputData.date}'s`;
      allStatusData = chartInputData.statusData || [];
    }

    if (visibleChartType) {
      if (visibleChartType === "power") {
        dataType1 = "solar_status";
        dataType2 = "load_status";
        valueField1 = "solar_status";
        valueField2 = "load_status";
        labelField1 = "Power generated(W)";
        labelField2 = "Power consumed(W)";
      } else if (visibleChartType === "energy") {
        dataType1 = "energy_generated_this_day";
        dataType2 = "energy_consumed_this_day";
        valueField1 = "energy_generated_this_day";
        valueField2 = "energy_consumed_this_day";
        labelField1 = "Energy generated(Wh)";
        labelField2 = "Energy consumed(Wh)";
      } else if (visibleChartType === "battery status") {
        dataType1 = "solar_status";
        dataType2 = "battery_charging_status";
        valueField1 = "solar_status";
        valueField2 = "battery_charging_status";
        labelField1 = "Power generated(W)";
        labelField2 = "Battery status(W)";
      } else if (visibleChartType === "system temperature") {
        dataType1 = "system_temperature";
        dataType2 = "system_humidity";
        valueField1 = "system_temperature";
        valueField2 = "system_temperature";
        labelField1 = "System Temperature";
        labelField2 = "System Humidity";
      }
    }

    let dataToday =
      allStatusData &&
      allStatusData.map(statusData => {
        let status = {};
        if (statusNames.length > 0) {
          statusNames.forEach(stNm => {
            status = {
              ...status,
              ...statusData.status[stNm]
            };
          });
        } else {
          status = statusData.status;
        }
        return {
          data_arrival_time: statusData.created_at,
          powerGenerated: this.transformValue(dataType1, status, valueField1),
          powerConsumed: this.transformValue(dataType2, status, valueField2),
          ...this.transformValue(valueField3, status, valueField3)
        };
      });

    const weatherIconSize = 80;
    const weatherData = [];
    let lastDrawIdx = 0;

    allStatusData &&
      allStatusData.forEach((statusData, _) => {
        let status = statusData.status;
        let dataPoint = status &&
          status.weather &&
          status.weather.weather && {
            data_arrival_time: statusData.created_at,
            description: status.weather.weather[0].description,
            temperature: (status.weather.main.temp / 10).toFixed(1),
            minTemperature: (status.weather.main.temp_min / 10).toFixed(1),
            maxTemperature: (status.weather.main.temp_max / 10).toFixed(1),
            icon: status.weather.weather[0].icon
          };

        if (dataPoint != null) {
          let lastDp = null;
          if (weatherData.length === 0) {
            lastDp = dataPoint;
          } else {
            lastDp = weatherData[lastDrawIdx];
          }

          const screenBigEnough = window.screen.availWidth > 500;

          let timeDiff =
            (new Date(dataPoint.data_arrival_time) -
              new Date(lastDp.data_arrival_time)) /
              3600000 >
            2.5;

          let draw =
            timeDiff &&
            screenBigEnough /*||
            lastDp.description !== dataPoint.description ||
            weatherData.length === 0*/;

          if (draw) {
            lastDrawIdx = weatherData.length;
          }

          weatherData.push({
            data_arrival_time: dataPoint.data_arrival_time,
            description: dataPoint.description,
            icon: `https://openweathermap.org/img/wn/${dataPoint.icon}@2x.png`,
            minTemperature:
              lastDp.minTemperature < dataPoint.temperature
                ? lastDp.minTemperature
                : dataPoint.temperature,
            maxTemperature:
              lastDp.maxTemperature > dataPoint.temperature
                ? lastDp.maxTemperature
                : dataPoint.temperature,
            draw: draw
          });
        }
      });

    const renderWeather = function(param, api) {
      let categoryIndex = api.value(0);
      if (weatherData[categoryIndex].draw !== true) {
        return null;
      }
      let point = api.coord([categoryIndex, 0]);

      return {
        type: "group",
        children: [
          {
            type: "image",
            style: {
              image: weatherData[categoryIndex].icon,
              x: -weatherIconSize / 2,
              y: -weatherIconSize / 2,
              width: weatherIconSize,
              height: weatherIconSize
            },
            position: [point[0], 40]
          },
          {
            type: "text",
            style: {
              text:
                weatherData[param.dataIndex].minTemperature +
                " - " +
                weatherData[param.dataIndex].maxTemperature +
                "°",
              textFont: api.font({ fontSize: 14 }),
              textAlign: "center",
              textVerticalAlign: "bottom"
            },
            position: [point[0], 82]
          }
        ]
      };
    };

    let options = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985"
          }
        }
      },
      legend: {
        data: [labelField1, labelField2]
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      grid: {
        left: "1%",
        right: "1%",
        bottom: "1%",
        containLabel: true
      },
      xAxis: [
        {
          boundaryGap: false,
          data: dataToday.map(dp =>
            dayjs(dp.data_arrival_time)
              .tz(tz)
              .format("LT")
          )
        }
      ],
      yAxis: [
        {
          type: "value"
        }
      ],
      series: [
        {
          name: labelField1,
          type: "line",
          areaStyle: {},
          emphasis: {
            focus: "series"
          },
          data: dataToday.map(dp => dp.powerGenerated)
        },
        {
          name: labelField2,
          type: "line",
          areaStyle: {},
          emphasis: {
            focus: "series"
          },
          data: dataToday.map(dp => dp.powerConsumed)
        },
        {
          name: labelField3,
          type: "line",
          areaStyle: {},
          emphasis: {
            focus: "series"
          },
          data: dataToday.map(dp => dp.powerExported)
        },
        {
          type: "custom",
          renderItem: renderWeather,
          data: weatherData,
          tooltip: {
            trigger: "item",
            formatter: function(param) {
              return param;
            }
          }
        }
      ]
    };

    return (
      <div>
        <h3>
          <strong
          >{`${selectedDay} ${visibleChartType} details over time`}</strong>
        </h3>
        {showDateSelection && (
          <div className={classes.chartSelectionContainer}>
            <CustomInput
              labelText="Date"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "date",
                value: chartInputData && chartInputData.date,
                onChange: this.onChartDateChange(chartIdx, "date")
              }}
            />
          </div>
        )}
        <div className={classes.chartSelectionContainer}>
          <label className={classes.chartTypeLabel}>Chart Type</label>
          <CustomSelect
            className={classes.chartTypeSelector}
            labelText={visibleChartType}
            items={AVAILABLE_CHART_TYPES}
            onChange={this.onChartTypeChange(chartIdx, "visibleChartType")}
          />
        </div>
        <ReactEcharts option={options} />
      </div>
    );
  }

  renderDeviceLoadInfo(deviceData) {
    const { classes } = this.props;
    const loadData = deviceData.loads_today;
    const allStatusData = deviceData.status_data_today || [];
    let currentLoad = [];
    if (allStatusData.length > 0) {
      let status = allStatusData[allStatusData.length - 1];
      currentLoad = status && status.status && status.status.loads;
    }
    const currency =
      (deviceData.properties && deviceData.properties.currency) || "$";

    let loadInfo = Object.keys(loadData).map(loadName => {
      let data = { ...loadData[loadName] };
      currentLoad.forEach(loadInfo => {
        if (loadInfo.name.toLowerCase() === loadName.toLowerCase()) {
          data["currentlyRunning"] = loadInfo.qty;
          data["currentPower"] = loadInfo.power;
        }
      });
      data["name"] = loadName;
      return data;
    });

    let totalLoad =
      deviceData.latest_data &&
      deviceData.latest_data.load_meter &&
      (deviceData.latest_data.load_meter.power || 0).toFixed(2);

    return (
      loadInfo && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h3>
              <strong
              >{`Today's load details (${totalLoad} W current load)`}</strong>
            </h3>
            {loadInfo &&
              loadInfo.length > 0 && (
                <Table
                  tableHeaderColor="warning"
                  tableHead={[
                    "Load",
                    "Currently Running",
                    "Current Power",
                    "Energy",
                    "Amount",
                    "Hours"
                  ]}
                  tableData={loadInfo.map(load => [
                    <img
                      className={
                        load.currentlyRunning > 0
                          ? classes.loadOnImg
                          : classes.loadOffImg
                      }
                      key={load.name}
                      src={loadIcons[load.name]}
                      alt="..."
                    />,
                    load.currentlyRunning || 0,
                    `${
                      load.currentPower
                        ? (load.currentPower / 1000.0).toFixed(1)
                        : "0"
                    } KW`,
                    `${load.energy.toFixed(1)} KWh`,
                    `${load.money.toFixed(1)} ${currency}`,
                    `${load.hours}:${load.minutes} hrs`
                  ])}
                  coloredColls={[3]}
                  colorsColls={["primary"]}
                />
              )}
          </GridItem>
        </GridContainer>
      )
    );
  }

  renderSystemStatus(deviceData) {
    // const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h3>
            <strong>System Status</strong>
          </h3>
          <Battery
            capacityKwh={43}
            capacityPercentage={45}
            status="charging"
            capColor="#873e23"
            backgroundColor="#eeeee4"
            outlineColor="#873e23"
            textColor="#ffffff"
            height={100}
            width={80}
          />
        </GridItem>
      </GridContainer>
    );
  }

  renderChartsSelection(deviceData) {
    const { classes } = this.props;
    return (
      <div className={classes.alarmsContainer}>
        <div style={{ paddingTop: "30px" }}>
          {this.state.visibleCharts &&
            this.state.visibleCharts.map((_, idx) => {
              return this.renderConsumptionChart(deviceData, true, idx);
            })}
        </div>
        <div className={classes.alarmActions}>
          <Button
            size="sm"
            color="info"
            className={classes.marginRight}
            onClick={_ => this.addNewChart(deviceData)}
          >
            Add new chart
          </Button>
        </div>
      </div>
    );
  }

  renderDevice(deviceData) {
    // const { classes } = this.props;
    const deviceType = deviceData.type;
    if (
      !Object.keys(DEVICE_TYPES).includes(deviceType) &&
      deviceData.other_data.enableStatusCharts !== true
    ) {
      return <h6>Status not available!</h6>;
    }

    return (
      <div>
        {this.renderStatusSummary(deviceData)}
        {this.renderDeviceTips(deviceData)}
        {this.renderDeviceLoadInfo(deviceData)}
        {this.renderConsumptionChart(deviceData)}
        {this.renderChartsSelection(deviceData)}
        {/* {this.renderSystemStatus(deviceData)} */}
      </div>
    );
  }

  renderAlarmDetails(deviceData) {
    const { classes } = this.props;
    return (
      <div className={classes.alarmsContainer}>
        <div className={classes.alarmActions}>
          <Button
            size="sm"
            color="info"
            className={classes.marginRight}
            onClick={e => this.setState({ alarmModalOpen: true })}
          >
            Add new
          </Button>
          <Modal
            hideBackdrop
            open={this.state.alarmModalOpen}
            onClose={e => this.setState({ alarmModalOpen: false })}
            aria-labelledby="Add"
            aria-describedby="Add new alarm or trigger"
          >
            <Box className={classes.alarmModalBox} sx={{ width: 200 }}>
              <div className={classes.alarmSelectionContainer}>
                <label className={classes.chartTypeLabel}>Alarm Type</label>
                <CustomSelect
                  className={classes.alarmTypeSelector}
                  labelText={this.state.selectedAlarmType}
                  items={AVAILABLE_ALARM_TYPES}
                  onChange={this.onSelectionChange("alarmData", "alarmType")}
                />
              </div>
              <div className={classes.alarmSelectionContainer}>
                <Button onClick={e => this.setState({ alarmModalOpen: false })}>
                  Add
                </Button>
                <Button onClick={e => this.setState({ alarmModalOpen: false })}>
                  Cancel
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
        {deviceData.alarms && deviceData.alarms.length > 0 ? (
          <Table
            tableHeaderColor="primary"
            tableHead={["Alarm", "Trigger time"]}
            tableData={deviceData.alarms.map(alarm => [
              alarm.name,
              dayjs(alarm.triger_time).format(DATE_DISPLAY_FORMAT)
            ])}
            coloredColls={[3]}
            colorsColls={["primary"]}
          />
        ) : (
          <h6>No active alarms!</h6>
        )}
      </div>
    );
  }

  renderDeviceLogs() {
    const { classes } = this.props;
    const logsInputData = this.state.logsInputData || {};
    return (
      <div className={classes.alarmsContainer}>
        <div className={classes.deviceLogActions}>
          <CustomInput
            labelText="Date"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "date",
              value: logsInputData.startDate,
              onChange: this.onChange("logsInputData", "date")
            }}
          />
          <CustomInput
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: this.onChange("logsInputData", "logSearchPattern")
            }}
          />
        </div>
        <div className={classes.deviceLogs}>
          {this.filterLogs(logsInputData.logSearchPattern)}
        </div>
      </div>
    );
  }

  renderLatestTelemetry(deviceData) {
    const { classes, user } = this.props;
    const userPermissions = user.user_permissions || [];
    const userCanSeeRawDataDetails = userPermissions.includes(
      CAN_SEE_RAW_DATA_DETAILS
    );
    let statusTypes = deviceData.available_status_types || [];
    let availableStatusTypes = null;
    if (statusTypes == null || statusTypes.length === 0) {
      availableStatusTypes = [
        { value: "raw_data", text: "Raw Data" },
        { value: "DAILY_STATUS", text: "Daily Status" }
      ];
    } else {
      availableStatusTypes = statusTypes.map(stTp => {
        return { value: stTp.target_type, text: stTp.name };
      });
      availableStatusTypes.push({ value: "raw_data", text: "Raw Data" });
    }
    const downloadData = this.state.downloadData || {};
    return (
      <div className={classes.alarmsContainer}>
        <div className={classes.alarmActions}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.showRawData}
                onChange={() => {
                  this.setState({
                    showRawData: !this.state.showRawData
                  });
                }}
              />
            }
            label="Show raw data"
          />
          <FormControlLabel
            control={
              <Switch
                checked={this.state.hideStatusData}
                disabled={!this.state.showRawData}
                onChange={() => {
                  this.setState({
                    hideStatusData: !this.state.hideStatusData
                  });
                }}
              />
            }
            label="Hide status data"
          />
          <Button
            color="info"
            size="sm"
            className={classes.marginRight}
            onClick={() => this.setState({ dataDownloadModalOpen: true })}
          >
            Download
          </Button>
          <Modal
            hideBackdrop
            open={this.state.dataDownloadModalOpen}
            onClose={() => this.setState({ dataDownloadModalOpen: false })}
            aria-labelledby="Download"
            aria-describedby="Download Data"
          >
            <Box className={classes.alarmModalBox} sx={{ width: 200 }}>
              <div className={classes.alarmSelectionContainer}>
                <label className={classes.chartTypeLabel}>Data Type</label>
                <CustomSelect
                  className={classes.alarmTypeSelector}
                  labelText={this.state.selectedAlarmType}
                  items={availableStatusTypes}
                  onChange={this.onSelectionChange("downloadData", "dataType")}
                />
              </div>
              <div className={classes.alarmSelectionContainer}>
                <CustomInput
                  labelText="Start Date"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "date",
                    value: downloadData.startDate,
                    onChange: this.onChange("downloadData", "startDate")
                  }}
                />
              </div>
              <div className={classes.alarmSelectionContainer}>
                <CustomInput
                  labelText="End Date"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "date",
                    value: downloadData.endDate,
                    onChange: this.onChange("downloadData", "endDate")
                  }}
                />
              </div>
              <div className={classes.alarmSelectionContainer}>
                <Button onClick={() => this.downloadDeviceData()}>
                  Download
                </Button>
                <Button
                  onClick={() =>
                    this.setState({ dataDownloadModalOpen: false })
                  }
                >
                  Cancel
                </Button>
              </div>
            </Box>
          </Modal>
        </div>

        <div>
          {this.state.showRawData === false
            ? this.renderDeviceMeters(deviceData)
            : this.renderRawData(deviceData, userCanSeeRawDataDetails)}
        </div>
      </div>
    );
  }

  renderRawData(deviceData, userCanSeeRawDataDetails) {
    const { classes } = this.props;
    const latestData = deviceData.latest_data;
    const keysToHide = userCanSeeRawDataDetails ? [] : RAW_DATA_KEYS_TO_HIDE;
    const showStatusData = !this.state.hideStatusData || false;
    // Each Column Definition results in one Column.
    // const [columnDefs, setColumnDefs] = useState([
    //   {field: 'make', filter: true},
    //   {field: 'model', filter: true},
    //   {field: 'price'}
    // ]);

    // const dataList = [];
    // latestData.raw_data_last_5 &&
    //   latestData.raw_data_last_5.forEach(data => {
    //     const flatData = {};
    //     flattenJson(data, flatData, "");
    //     dataList.push(flatData);
    //   });

    // const columnDefs = Object.keys(dataList[0]).map(key => {
    //   return {
    //     field: key
    //   };
    // });

    return (
      <div className={classes.rawTelemetryDataContainer}>
        {latestData.raw_data_last_5
          ? latestData.raw_data_last_5
              .filter(data => showStatusData || data.data_type !== "status")
              .map((data, idx) => {
                return (
                  <ExpansionPanel
                    key={`raw-data-${idx}`}
                    defaultExpanded={idx === 0}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.heading}>
                        Updated:{" "}
                        {dayjs(data.data_arrival_time + "+0000").fromNow()}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {prettyJson(data, classes, keysToHide)}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                );
              })
          : prettyJson(latestData, classes, keysToHide)}
        {/* <div className="ag-theme-alpine" style={{ width: 500, height: 500 }}>
          <AgGridReact
            rowData={[dataList[0]]} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            // defaultColDef={defaultColDef} // Default Column Properties
            // animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            // rowSelection="multiple" // Options - allows click selection of rows
            // onCellClicked={cellClickedListener} // Optional - registering for Grid Event
          />
        </div> */}
      </div>
    );
  }

  renderDevicesMap(deviceData) {
    let center = [52.5103175, 13.3275342];
    let dataPoints = [];
    let lat = null,
      long = null;
    if (
      deviceData.other_data &&
      deviceData.other_data.latitude &&
      deviceData.other_data.longitude
    ) {
      lat = deviceData.other_data.latitude;
      long = deviceData.other_data.longitude;
    } else if (
      deviceData.position &&
      deviceData.position.latitude &&
      deviceData.position.longitude
    ) {
      lat = deviceData.position.latitude;
      long = deviceData.position.longitude;
    }
    if (lat != null && long != null) {
      dataPoints.push({
        latitude: parseFloat(lat),
        longitude: parseFloat(long),
        name: deviceData.alias,
        url: deviceData.url
      });
      center = [parseFloat(lat), parseFloat(long)];
    }
    return (
      <GridContainer>
        <MapLeft center={center} dataPoints={dataPoints} zoom={8} />
      </GridContainer>
    );
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes, user } = this.props;
    const userPermissions = user.user_permissions || [];
    const userCanSeeLogs =
      userPermissions.includes(PERMISSIONS_ADMIN) ||
      userPermissions.includes(CAN_SEE_DEVICE_LOGS);
    const { deviceData } = this.state;
    if (deviceData === undefined) return null;

    const deviceType = deviceData.type;

    const latestData = deviceData.latest_data;
    const properties = deviceData.properties;
    const weatherData = deviceData.latest_weather_data;
    let netMeterStatus = properties && properties.net_meter_status;
    let batteryStatus = properties && properties.battery_charging_status;
    let loadStatus = properties && properties.load_status;
    let solarStatus = properties && properties.solar_status;
    let systemTemperature = properties && properties.system_temperature;

    let lastUpdateTime = latestData.last_update_time;
    let lastHeartbeatTime =
      deviceData.other_data && deviceData.other_data.last_heartbeat_time;

    if (lastUpdateTime == null) {
      lastUpdateTime =
        deviceData.other_data && deviceData.other_data.last_data_sync_time;
    }

    let deviceAvatar = `${MEDIA_ROOT}${deviceData.avatar}`;

    // set interval to fetch data periodically
    logsInterval = setInterval(this.timeToFetch, 60000);

    let availableTabs = [];
    if (
      Object.keys(DEVICE_TYPES).includes(deviceType) ||
      deviceData.other_data.enableStatusCharts === true
    ) {
      availableTabs.push({
        tabButton: "Status",
        //tabIcon: SettingsApplications,
        tabContent: this.renderDevice(deviceData)
      });
    }
    availableTabs = availableTabs.concat([
      {
        tabButton: "Latest telemetry",
        //tabIcon: SettingsApplications,
        tabContent: this.renderLatestTelemetry(deviceData)
      },
      {
        tabButton: "Alarms",
        //tabIcon: SettingsApplications,
        tabContent: this.renderAlarmDetails(deviceData)
      },
      {
        tabButton: "Details",
        //tabIcon: SettingsApplications,
        tabContent: (
          <DeviceDetailsTable
            deviceData={deviceData}
            user={this.props.user}
            axios={this.props.axios}
          />
        )
      }
    ]);

    if (userCanSeeLogs) {
      availableTabs.push({
        tabButton: "Device Logs",
        //tabIcon: SettingsApplications,
        tabContent: this.renderDeviceLogs()
      });
    }

    return (
      <GridContainer key={`project-detail`}>
        <GridItem xs={12} sm={12} md={12}>
          {this.renderDevicesMap(deviceData)}
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          key={`project-${this.props.projectId}`}
        >
          <Card chart className={classes.cardHover}>
            <CardHeader className={classes.cardHeaderHover}>
              <CardAvatar project className={classes.cardIcon}>
                {netMeterStatus && solarStatus ? (
                  <LCD
                    backlit
                    lines={[
                      netMeterStatus && `Status: ${netMeterStatus}`,
                      solarStatus && `Solar: ${solarStatus}`,
                      batteryStatus && `Battery: ${batteryStatus}`,
                      loadStatus && `Load: ${loadStatus}, ${systemTemperature}`,
                      lastUpdateTime &&
                        `Updated: ${dayjs(lastUpdateTime).fromNow()}`
                    ]}
                  />
                ) : (
                  deviceData.avatar && (
                    <a href="#avatar" onClick={e => e.preventDefault()}>
                      <img
                        className={classes.img}
                        src={deviceAvatar}
                        alt="..."
                      />
                    </a>
                  )
                )}
              </CardAvatar>
              <div className={classes.deviceInfoHeaderContainer}>
                <div className={classes.deviceInfo}>
                  <h2 style={styles.cardTitleBlack}>
                    {deviceData.name || deviceData.alias}
                  </h2>
                  <small>{deviceData.ip_address}</small>
                  {lastHeartbeatTime != null && (
                    <div style={styles.updateInfo}>
                      <label style={styles.updateInfoLabel}>
                        Last update:{" "}
                      </label>
                      <large>
                        {dayjs(lastHeartbeatTime).format(
                          DATE_DISPLAY_FORMAT_DETAILED
                        )}
                        , {dayjs(lastHeartbeatTime).fromNow()}
                      </large>
                    </div>
                  )}
                  <div style={styles.dataUpdateInfo}>
                    <label style={styles.dataUpdateInfoLabel}>
                      Last sync:{" "}
                    </label>
                    {lastUpdateTime != null ? (
                      <large>
                        {dayjs(lastUpdateTime).format(
                          DATE_DISPLAY_FORMAT_DETAILED
                        )}
                        , {dayjs(lastUpdateTime).fromNow()}
                      </large>
                    ) : null}
                  </div>
                </div>
                {weatherData &&
                  weatherData.main &&
                  weatherData.weather &&
                  weatherData.sys && (
                    <div className={classes.deviceWeatherInfo}>
                      <div className={classes.cityName}>
                        <h2 style={styles.cardTitleBlack}>
                          <span>{weatherData.name}</span>
                          <sup>{weatherData.sys.country}</sup>
                        </h2>
                        <div className={classes.cityTemperature}>
                          {Math.round(weatherData.main.temp - 273.15, 1)}
                          <sup>&deg;C</sup>
                        </div>
                      </div>
                      <div className={classes.cityWeatherImage}>
                        <img
                          className={classes.cityWeatherIcon}
                          src={`https://openweathermap.org/img/wn/${
                            weatherData.weather[0].icon
                          }@2x.png`}
                          alt={weatherData.weather[0].description}
                        />
                        <strong>{weatherData.weather[0].description}</strong>
                      </div>
                    </div>
                  )}
              </div>
            </CardHeader>
            <CardBody>
              <NavPills
                color="warning"
                vertical={{
                  tabsGrid: { xs: 12, sm: 12, md: 12 },
                  contentGrid: { xs: 12, sm: 12, md: 12 }
                }}
                tabs={availableTabs}
                onChange={this.handleTabChange}
              />
            </CardBody>
            <CardFooter stats>
              <a href="/dashboard" className={classes.marginRight}>
                Home
              </a>
              {Object.keys(DEVICE_TYPES).includes(deviceType) && (
                <a
                  href={`/devicereports/${deviceData.ip_address}/`}
                  className={classes.marginRight}
                >
                  Report
                </a>
              )}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);
